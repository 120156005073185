import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { parse } from 'query-string'
import Layout from './Layout'
import { setContractCode, getContractCode } from '../../services/auth'
import * as API from '../../services/api'

export default function Signup(props) {
  const query = parse(props.location.search)

  const [code, setCode] = useState(null)
  const [contract, setContract] = useState(null)

  useEffect(() => {
    const newCode = query.code || getContractCode()
    if (newCode) {
      if (newCode !== code) {
        setCode(newCode)
      }
    } else {
      navigate('/')
    }

    if (!query.email) {
      navigate('/')
    }
  }, [query.code, query.email, code])

  useEffect(() => {
    if (code && contract === null) {
      API.getSignupContract(code).then((response) => {
        const newContract = response?.data?.contract
        if (newContract) {
          setContractCode(newContract.code)
          setContract(newContract)
        } else {
          navigate('/')
        }
      })
    }
  }, [code, contract])

  return <Layout>{props.children}</Layout>
}

import React from 'react'
import styled from 'styled-components'
import { FlatPanel } from '../shared/elements'

const Panel = styled(FlatPanel)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 350px;

  @media (max-width: 767px) {
    width: 100%;
  }
`

export default (props) => {
  return (
    <div>
      <Panel className="full-width full-height flex-center">
        <div className="full-width">
          <h4 className="text-center mb-5">Heylink Connect</h4>
          {props.children}
        </div>
      </Panel>
    </div>
  )
}

import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { parse } from 'query-string'
import { useForm } from 'react-hook-form'
import { TextField } from '../shared/fields'
import { login, getContractCode } from '../../services/auth'
import * as API from '../../services/api'

export default function SignupForm(props) {
  const query = parse(props.location.search)

  const [isSubmitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')

  const form = useForm({
    defaultValues: {
      name: '',
      email: query.email,
      password: '',
    },
  })

  const onSubmit = async (values) => {
    values.code = getContractCode()

    setError('')
    setSubmitting(true)

    API.signup(values)
      .then(async (response) => {
        if (response?.data?.success) {
          const loggedIn = await login(values)
          setSubmitting(false)

          if (loggedIn) {
            navigate('/')
          } else {
            setError('Incorred email or password')
          }
        } else {
          setSubmitting(false)
          setError(response.message)
          return
        }
      })
      .catch((error) => {
        setSubmitting(false)
        setError(error.message)
      })
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <TextField
        form={form}
        validation={{ required: true }}
        disabled={true}
        type="email"
        name="email"
        label="Email"
      />
      <TextField
        form={form}
        validation={{ required: true }}
        name="name"
        label="Full name"
      />
      <TextField
        form={form}
        validation={{ required: true }}
        type="password"
        name="password"
        label="Password"
      />
      <TextField
        form={form}
        validation={{ required: true }}
        type="password"
        name="passwordConfirmation"
        label="Confirm password"
      />

      {error && <div className="text-danger mb-3">{error}</div>}

      <div className="actions text-right mt-3">
        <button
          type="submit"
          disabled={isSubmitting}
          className="btn btn-primary full-width"
        >
          Signup
        </button>
      </div>
    </form>
  )
}

import React from 'react'
import { Router } from '@reach/router'
import Layout from '../components/signup'
import SignupForm from '../components/signup/Form'
import { Head } from '../components/shared/elements'
import { signupPath } from '../utils/paths'

export default (props) => {
  return (
    <>
      <Head />
      <Layout {...props}>
        <Router>
          <SignupForm path={signupPath()} />
        </Router>
      </Layout>
    </>
  )
}
